import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Form, Select, Input, Typography, Image, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import _bank from "./bank.json";
import api from "../api";

import "./bank.scss";
import { useSelector } from "react-redux";
export default function UpdateBank() {
  const router = useHistory();
  const [state, setState] = useState({
    bankName: "",
    logo: "",
    number: "",
    name: "",
    date: "",
  });
  const [dataProfile, setDataProfile] = useState({});
  const { profile } = useSelector((state) => state._auth);

  useEffect(() => {
    setDataProfile(profile);
  }, [profile]);

  // const loadData = async () => {
  //   try {
  //     const { data: data2 } = await api.get("/users/profil1e");
  //     setDataProfile(data2.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const onFinish = async (e) => {
    // const contract = JSON.parse(localStorage.getItem("contract"));
    await api.post("/users/verify", {
      bank: { ...e },
    });
    // await api.post("/contracts", {
    //   times: contract.times || 6,
    //   amount: contract.amount || 30000000,
    // });
    router.push("my-info");
  };

  return (
    <motion.div
      initial={{ opacity: 0.3 }}
      animate={{ opacity: 1 }}
      className="bank"
    >
      <div className="header-screen mb-0">
        <div className="icon-header" onClick={() => router.goBack()}>
          <LeftOutlined />
        </div>
        <span>Thẻ ngân hàng</span>
      </div>
      {dataProfile && dataProfile.kyc && dataProfile.kyc.bank ? (
        <div className="bank-body">
          <div className="bank-body-block">
            <div className="bank-body-block--item">
              <p className="title">Tên ngân hàng: </p>
              <p className="value">
                {dataProfile?.kyc?.bank?.bank_name || "Chưa có thông tin"}
              </p>
            </div>
            <div className="bank-body-block--item">
              <p className="title">Thẻ ngân hàng: </p>
              <p className="value">
                {dataProfile?.kyc?.bank?.bank_number || "Chưa có thông tin"}
              </p>
            </div>
            <div className="bank-body-block--item">
              <p className="title">Tên chủ thẻ: </p>
              <p className="value">
                {dataProfile?.kyc?.bank?.bank_owner || "Chưa có thông tin"}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="bank-body">
          <Form layout="vertical" onFinish={onFinish}>
            <div className="form-body">
              <Form.Item
                name="bank_name"
                label="Tên ngân hàng"
                rules={[
                  {
                    required: true,
                    message: "Tên ngân hàng",
                  },
                ]}
              >
                <Select
                  className="select-bank"
                  placeholder="Tên ngân hàng"
                  size="large"
                  onChange={(e) =>
                    setState({
                      ...state,
                      bankName: e.split("@")[0],
                      logo: e.split("@")[1],
                    })
                  }
                >
                  {_bank.banksnapas.map((item) => (
                    <Select.Option value={`${item.shortName}`}>
                      <div>
                        <Typography.Text strong>
                          {" "}
                          {item.shortName}
                        </Typography.Text>{" "}
                        - <Typography.Text>{item.vn_name}</Typography.Text>
                      </div>
                      {item.logo && (
                        <Image src={item.logo} preview={false} width="20%" />
                      )}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="bank_number"
                label="Thẻ ngân hàng"
                rules={[
                  {
                    required: true,
                    message: "Cần nhập thẻ ngân hàng",
                  },
                ]}
              >
                <Input size="large" placeholder="Thẻ ngân hàng" />
              </Form.Item>
              <Form.Item
                name="bank_owner"
                label="Tên chủ thẻ"
                rules={[
                  {
                    required: true,
                    message: "Tên chủ thẻ",
                  },
                ]}
              >
                <Input size="large" placeholder="Tên chủ thẻ" />
              </Form.Item>
            </div>
            <div className="btn-group">
              <Button
                className="btn-submit"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Tiếp tục
              </Button>
            </div>
          </Form>
        </div>
      )}
    </motion.div>
  );
}
