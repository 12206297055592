/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Typography,
  Button,
  Divider,
  Modal,
  Input,
  message,
  Carousel,
} from "antd";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/actions/auth";
import ContractForm from "../components/Contract";
import { useHistory } from "react-router-dom";
import { CaretRightOutlined } from "@ant-design/icons";
import api from "../api";
import {
  RightOutlined,
  UserOutlined,
  WalletOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import banner from "../assets/new-ui/profile.jpg";
import commonService from "../service";

import "./profile.scss";

export default function Profile() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [textRun, setTextRun] = useState([]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const router = useHistory();
  const dispatch = useDispatch();
  const { status, profile } = useSelector((state) => state._auth);
  const [token] = useState(localStorage.getItem("access_token"));

  const getRandomDataPhoneRun = () => {
    let arrTextRun = [];
    for (let i = 0; i < 50; i++) {
      arrTextRun.push({
        phone: commonService.generateRandomPhoneNumber(),
        profit: commonService.generateRandomNumber(30000000, 190000000),
      });
    }
    setTextRun(arrTextRun);
  };

  useEffect(() => {
    if (token) {
      (async () => {
        try {
          getRandomDataPhoneRun();
          // const { data } = await api.get("/users/profile");
          // if (data.data.isLocked) showModal();
          // dispatch(actions.initialLogin(data.data));
        } catch (err) {}
      })();
    } else {
      router.push("/home");
    }
    // if (!token) router.push("/home");
  }, [status]);

  return (
    <div className="profile-block">
      <div
        className="header-banner"
        style={{
          background: `url(${banner}) center center / cover no-repeat`,
        }}
      />
      <div className="body-profile">
        <div className="body-profile--block mb-10">
          {/* Thông tin của tôi */}
          <div
            className="body-profile--block__item"
            onClick={async () => {
              router.push("/my-info");
            }}
          >
            <div className="prefix-icon">
              <UserOutlined />
            </div>
            <span className="title">Thông tin của tôi</span>
            <div className="surfix-icon">
              <RightOutlined />
            </div>
          </div>
          {/* Tài khoản */}
          <div
            className="body-profile--block__item"
            onClick={async () => {
              router.push("/wallet");
            }}
          >
            <div className="prefix-icon">
              <WalletOutlined />
            </div>
            <span className="title">Tài khoản</span>
            <div className="surfix-icon">
              <RightOutlined />
            </div>
          </div>
          {/* Hợp đồng */}
          <ContractForm profile={profile} />
        </div>

        <div className="body-profile--block">
          {/* Đăng xuất */}
          <div
            className="body-profile--block__item"
            onClick={async () => {
              dispatch(actions.Logout());
              router.push("/login");
            }}
          >
            <div className="prefix-icon">
              <UserOutlined />
            </div>
            <span className="title">Đăng xuất</span>
            <div className="surfix-icon">
              <RightOutlined />
            </div>
          </div>
        </div>
        <Carousel
          dots={false}
          slidesToShow={1}
          dotPosition="right"
          autoplay
          autoplaySpeed={2000}
          className="carousel-phone-container"
        >
          {textRun.map((item, index) => {
            return (
              <div className="content-item" key={index}>
                <CaretRightOutlined />
                {/* <i className="fa-solid fa-play"></i> */}
                Chúc mừng: <span className="phone">{item.phone}</span> đã rút
                thành công{" "}
                <span className="amount">
                  {item.profit.toLocaleString("vi")} ₫
                </span>
              </div>
            );
          })}
        </Carousel>
        {/* <div className="member-new">
          <Carousel dotPosition="right" autoplay autoplaySpeed={2000}>
            {textRun.map((item, index) => {
              return (
                <div className="member-new-card" key={index}>
                  <div className="card-left">
                    <div className="left-text">
                      <div className="texts">{item.phone}</div>
                    </div>
                  </div>
                  <div className="card-right">
                    <div className="right-text">{item.profit}</div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div> */}
      </div>
      <Modal className="noti-modal" visible={isModalOpen}>
        <p>Hồ sơ quý khách đang tạm khoá, vui lòng liên hệ CSKH</p>
        <div className="to-cskh" onClick={() => router.push("/cskh")}>
          CSKH
        </div>
      </Modal>
    </div>
  );
}
